import { CONSOLE_LEVELS } from '@sentry/utils';
import { useEffect, useRef, useState } from 'react';

export default function useIntersectionObserver(
  threshold = 0,
  rootMargin = '',
  triggerOnce = true,
  initialVisibleValue = false
) {
  const positionEnum = Object.freeze({
    below: 'BELOW',
    over: 'OVER',
    center: 'CENTER',
  });

  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(initialVisibleValue);
  const [position, setPosition] = useState(null);

  useEffect(() => {
    let element = ref.current;

    const cb = (entries) => {
      const [entry] = entries;

      if (entry.boundingClientRect.top > 0) {
        setPosition(positionEnum.below);
        setIsVisible(false);
      } else if (!entry.isIntersecting) {
        setPosition(positionEnum.over);
        setIsVisible(false);
      }

      if (entry.isIntersecting) {
        setIsVisible(true);
        setPosition(positionEnum.center);

        if (triggerOnce) {
          observer.unobserve(element);
        }
      }
    };

    const observer = new IntersectionObserver(cb, {
      threshold,
      rootMargin,
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [ref, threshold, rootMargin]);

  return [ref, isVisible, position];
}
