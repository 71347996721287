import classNames from 'classnames';
import React, { useContext } from 'react';
import { GradientContext, BackgroundContext } from '../../containers/BasePage';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import s from './GradientBlurb.module.scss';

const GradientBlurb = ({ isReversed, modifiers = [] }) => {
  const gradient = useContext(GradientContext) || 'PurpleBlue';
  const background = useContext(BackgroundContext) || false;

  const { whiteBackground } = background;
  const classes = classNames(
    s.Root,
    {
      [s.Reverse]: isReversed,
    },
    s[gradient],
    modifiers.map((m) => s[m])
  );
  return (
    !whiteBackground && (
      <span className={classes}>
        <span className={s.Bg} />
      </span>
    )
  );
};

GradientBlurb.propTypes = {};

GradientBlurb.defaultProps = {};

export default GradientBlurb;
