import React, { useContext } from 'react';

// import PropTypes from 'prop-types';
import ButtonPrimaryEllipse from '../ButtonPrimaryEllipse';
import GradientBlurb from '../GradientBlurb';
import PhotoCred from '../PhotoCred';
import s from './CardGlassLink.module.scss';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { BackgroundContext } from '../../containers/BasePage';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { trackReadMore } from '../../utils/dataLayer';
import classNames from 'classnames';

const CardGlassLink = ({
  image = {},
  title,
  text,
  href = {},
  reverse = false,
  backgroundColor = 'black',
  fromPage,
}) => {
  const [ref, isVisible] = useIntersectionObserver(0, '', true);

  const background = useContext(BackgroundContext) || false;

  let bgColor = backgroundColor;
  if (background?.hasSetBg) {
    bgColor = background?.whiteBackground ? 'white' : 'black';
  }

  const { whiteBackground } = background;
  const componentHasNoImage = !image?.url;
  const classes = classNames(s.Root, s[fromPage], {
    [s.NoImage]: componentHasNoImage,
    [s.Reverse]: reverse,
    [s.Visible]: isVisible,
    [s.White]: bgColor === 'white',
    [s.Black]: bgColor === 'black',
  });

  const cardContentClasses = classNames(s.CardContent, {
    'black-glass': bgColor === 'black',
    'white-glass': bgColor === 'white',
  });
  const { t } = useTranslation();

  const isExternal =
    href?.href?.indexOf('http://') !== -1 ||
    href?.href?.indexOf('https://') !== -1;

  const imageStyle = { objectFit: 'cover' };

  if (!!image && !!image.focal && !!image.focal.x && !!image.focal.y) {
    imageStyle.objectPosition = `${image.focal.x} ${image.focal.y}`;
  }

  const handleTrackReadMore = (e) => {
    e.preventDefault();

    trackReadMore(title, 'Card glass link');

    setTimeout(() => {
      window.location.href = href?.href;
    }, 200);
  };

  return (
    <section className={classes} ref={ref}>
      <div className={`${s.Wrap} grid`}>
        {!!image.url && (
          <div className={s.ImageContainer}>
            {backgroundColor === 'black' && (
              <GradientBlurb modifiers={['HomePage']} />
            )}
            <Image
              className={s.Image}
              src={image?.url}
              alt=""
              fill
              style={imageStyle}
            />
            <PhotoCred
              credit={image?.credit}
              modifiers={['CardGlass', reverse && 'Reverse']}
            />
          </div>
        )}
        <div className={cardContentClasses}>
          <div className={s.Content}>
            <h3 className={s.Heading}>{title}</h3>
            <div className={s.Text}>{text}</div>
          </div>
          {!!href.href && (
            <div className={s.Button}>
              <ButtonPrimaryEllipse
                asLink
                href={href.href}
                onColor={bgColor}
                onClick={handleTrackReadMore}
                modifiers={
                  componentHasNoImage
                    ? ['ShowTextMobile', `${backgroundColor}`]
                    : [`${backgroundColor}`]
                }
                icon={
                  isExternal ? 'icon-external-white' : 'icon-arrow-right-white'
                }
                iconDimensions={isExternal ? [18, 18] : [20, 20]}>
                {!!href.text ? href.text : t('Generic.readMore')}
                <span className="sr-only"> {title}</span>
              </ButtonPrimaryEllipse>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

CardGlassLink.propTypes = {};

CardGlassLink.defaultProps = {};

export default CardGlassLink;
