import React, { useContext } from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import s from './ButtonPrimaryEllipse.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import classNames from 'classnames';
import { GradientContext } from '../../containers/BasePage';

const ButtonPrimaryEllipse = ({
  asLink = false,
  children,
  disabled,
  size,
  icon = 'icon-arrow-right-white',
  iconDimensions = [20, 20],
  hideText = false,
  onColor = 'black' /* if the button is on a white component this is white */,
  modifiers = [],
  onClick,
  ...props
}) => {
  const gradient = useContext(GradientContext);

  const rootClasses = classNames(
    s.Root,
    {
      [s.Disabled]: !!disabled,
      [s.Small]: size === 'small',
      [s.OnWhite]: onColor !== 'black',
      [s.Play]: icon === 'icon-play',
    },
    s[gradient],
    modifiers.map((m) => s[m])
  );

  const [iconWidth, iconHeight] = iconDimensions;

  return asLink ? (
    <Link {...props} legacyBehavior={true}>
      <a onClick={onClick} className={rootClasses}>
        {icon && (
          <span className={s.IconWrap}>
            <Image
              width={iconWidth}
              height={iconHeight}
              src={`/img/${icon}.svg`}
              alt=""
              className={s.Icon}
            />
            {onColor === 'black' && <i className={s.Bg} aria-hidden={true} />}
          </span>
        )}
        <span className={s.Text}>
          {hideText ? <span className="sr-only">{children}</span> : children}
        </span>
      </a>
    </Link>
  ) : (
    <button type="button" onClick={onClick} className={rootClasses} {...props}>
      {icon && (
        <span className={s.IconWrap}>
          <Image
            width={20}
            height={20}
            src={`/img/${icon}.svg`}
            alt=""
            className={s.Icon}
          />
          {onColor === 'black' && <i className={s.Bg} aria-hidden={true} />}
        </span>
      )}
      <span className={s.Text}>
        {hideText ? <span className="sr-only">{children}</span> : children}
      </span>
    </button>
  );
};

ButtonPrimaryEllipse.propTypes = {};

ButtonPrimaryEllipse.defaultProps = {};

export default ButtonPrimaryEllipse;
