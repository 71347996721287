import React from 'react';

// import { useTranslation } from 'next-i18next';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './PhotoCred.module.scss';

const PhotoCred = ({ credit, modifiers = [] }) => {
  if (!credit) return null;
  const classes = classNames(
    s.Root,
    modifiers.map((m) => s[m])
  );
  return <span className={classes}>Photo: {credit}</span>;
};

PhotoCred.propTypes = {};

PhotoCred.defaultProps = {};

export default PhotoCred;
